//

const Footer = () => {
  return (
    <footer>
      <p>
        <small>© 2022 Louis Party Rental - All Rights Reserved.</small>
      </p>
    </footer>
  );
};

export default Footer;
